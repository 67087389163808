<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="listQuery.shop_ids" multiple collapse-tags placeholder="请选择店铺" style="width: 220px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker v-model="dateArr" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
      </el-date-picker>
      <el-select v-model="listQuery.order_type" placeholder="订单类型" style="width: 140px" clearable>
        <el-option label="食堂美食" :value="1"></el-option>
        <!-- <el-option label="舍购" :value="2"></el-option>
        <el-option label="夜猫店" :value="3"></el-option>
        <el-option label="零食盒子" :value="4"></el-option> -->
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button type="primary" icon="el-icon-download" @click="handleExportExcel">导出</el-button>
    </div>
    <div class="total">
      <div class="total-num">
        <span>{{total_pay_count}}</span>
        <span>销售数量合计</span>
      </div>
      <div class="total-price">
        <span>{{total_pay_sum}}</span>
        <span>销售金额合计(元)</span>
      </div>
    </div>

    <el-table id="rebateSetTable" v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row :default-sort="{prop: 'date', order: 'descending'}">
      <el-table-column label="营业日期" min-width="110" align="center" prop="date" sortable>
        <template slot-scope="scope">
          {{ scope.row.date }}
        </template>
      </el-table-column>
      <el-table-column label="菜品销售数量统计" min-width="110" align="center" prop="pay_count" sortable>
        <template slot-scope="scope">
          {{ scope.row.pay_count }}
        </template>
      </el-table-column>
      <el-table-column label="菜品退款数量合计" min-width="110" align="center" prop="refund_count" sortable>
        <template slot-scope="scope">
          {{ scope.row.refund_count }}
        </template>
      </el-table-column>
      <el-table-column label="实际销售金额合计(元)" min-width="110" align="center" prop="pay_sum" sortable>
        <template slot-scope="scope">
          {{ scope.row.pay_sum }}
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import FileSaver from "file-saver";
import XLSX from "xlsx";

var moment = require("moment");

export default {
  components: {},
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      shops: [],
      list: null,
      total: null,
      btnLoading: false,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        shop_ids: [],
        order_type: 1,
        start_date: '',
        end_date: '',
      },
      dateArr: [moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
      total_pay_count: 0,
      total_pay_sum: 0
    };
  },
  created() {
    this.getShopList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getShopList();
    },
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      if (!this.listQuery.school_id) {
        this.$message({
          type: "warning",
          message: "请选择学校"
        });
        return;
      }
      if (this.listQuery.shop_ids.length <= 0) {
        this.$message({
          type: "warning",
          message: "请选择店铺"
        });
        return;
      }
      if (!this.dateArr) {
        this.$message({
          type: "warning",
          message: "请选择日期"
        });
        return;
      }  else {
        this.listQuery.start_date = this.dateArr[0];
        this.listQuery.end_date = this.dateArr[1];
      }
      if (!this.listQuery.order_type) {
        this.$message({
          type: "warning",
          message: "请选择订单类型"
        });
        return;
      }
      this.listLoading = true;
      request({
        url: "/api/backend/finance/day",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total_pay_count = response.data.total_pay_count;
        this.total_pay_sum = response.data.total_pay_sum;
        this.listLoading = false;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id
        }
      }).then(response => {
        this.shops = response.data.data;
        this.listQuery.shop_ids = this.shops.map((element)=>{
          return element.id
        })
        this.getList();
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleExportExcel() {
      this.$nextTick(function() {
        let wb = XLSX.utils.table_to_book(
          document.querySelector("#rebateSetTable")
        );
        /* get binary string as output */
        let wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          FileSaver.saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "营业日结.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.total {
  width: 100%;
  height: 120px;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 10px auto;
  .total-num,
  .total-price {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    span {
      margin-bottom: 10px;
    }
  }
  .total-num {
    border-right: 1px solid #f0f0f0;
  }
}
</style>
